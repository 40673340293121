import React from 'react';
import Banner from '../Banner';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './TermsConditions.css';

const TermsConditions: React.FC = () => {
	return (
		<div>
			<Banner />
			<Navbar />
			<div className='privacy-policy'>
				<h1 style={{ textAlign: 'left', fontWeight: 'bold', color: 'black' }}>Subscription Terms and Conditions</h1>
				<p>
					These Subscription Terms and Conditions ("Terms") govern your access to and use of our DESMOS Solution sheet provided by AlphaPrep, LLC ("the Company"). By accessing or using the Solutions
					Tool (“ST”), you agree to be bound by these Terms.
				</p>
				<p>
					<strong>1. Subscription</strong>
					<br />
					<br />
					<strong>1.1 Subscription Plans: </strong>
					The ST is provided on a subscription basis. You may choose from the subscription plans available on our website, each of which differs in length and pricing.
					<br />
					<strong>1.2 Subscription Period: </strong>
					Your subscription will be effective for the subscription period specified during the registration process or as otherwise agreed between you and the Company.
					<br />
					<strong>1.3 Automatic Renewal: </strong>
					Unless you cancel your subscription before the end of the current subscription period, your subscription will automatically renew for a subsequent period, and you authorize the Company to
					charge the applicable subscription fees using the payment method provided.
				</p>
				<p>
					<strong>2. Payment</strong>
					<br />
					<br />
					<strong>2.1 Subscription Fees: </strong>
					You agree to pay all subscription fees specified during the registration process or as otherwise communicated to you by the Company.
					<br />
					<strong>2.2 Billing: </strong>
					Subscription fees are billed in advance on a recurring basis for renewal packages. You may select a package that is not renewable and therefore does not require a credit card on file. For
					auto-renewals, you authorize the Company to charge the applicable fees using the payment method provided.
				</p>
				<p>
					<strong>3. Use of the Solutions Tool</strong>
					<br />
					<br />
					<strong>3.1 License: </strong>
					Subject to these Terms, the Company grants you a non-exclusive, non-transferable, revocable license to access and use the Service for your internal purposes.
				</p>
				<p>
					<strong>4. Termination</strong>
					<br />
					<br />
					<strong>4.1 Termination by You: </strong>
					You may cancel your subscription at any time by following the cancellation instructions provided on our website or by contacting customer support. Upon cancellation, your access to the ST
					will terminate at the end of the current subscription period.
					<br />
					<strong>4.2 Termination by Company: </strong>
					The Company may suspend or terminate your access to the Service at any time for violation of these Terms or for any other reason, with or without notice.
				</p>
				<p>
					<strong>5. Privacy </strong>
					<br />
					<br />
					<strong>5.1 Privacy Policy: </strong>
					Your use of the ST is subject to the Company's Privacy Policy, which is incorporated by reference into these Terms.
				</p>
				<p>
					<strong>6. Limitation of Liability </strong>
					<br />
					<br />
					<strong>6.1 Disclaimer: </strong>
					To the fullest extent permitted by law, the ST is provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied.
				</p>
				<p>
					<strong>7. Miscellaneous</strong>
					<br />
					<br />
					<strong>7.1 Entire Agreement: </strong>
					These Terms constitute the entire agreement between you and the Company regarding your use of the ST, superseding any prior agreements between you and the Company.
					<br />
					<strong>7.2 Changes to Terms: </strong>
					The Company reserves the right to modify or revise these Terms at any time by posting the amended Terms on our website. Your continued use of the ST after any such changes constitutes your
					acceptance of the revised Terms.
				</p>
				<p>This Terms and conditions was last updated on May 1, 2024.</p>
			</div>
			<Footer />
		</div>
	);
};

export default TermsConditions;
