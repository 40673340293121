import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, MenuList, IconButton, Menu, MenuItem, Grid, Typography, Avatar } from '@mui/material';
import { Person, PhoneAndroidOutlined } from '@mui/icons-material';
import Logo from '../../assets/img/common/logo.png';
import './Navbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { updateUser } from '../../reduxstate/slices/userSlice';
import { Cookies } from 'react-cookie';
import { getDomain, getHostName , getMainUrl} from '../../shared';
import apiQuery from '../../apicall/apiQuery';

const StyledMenu = styled(Menu)(({ theme }) => ({
	'& .MuiMenu-paper': {
		backgroundColor: '#0146C7 !important',
	},
}));

const NavigationBar: React.FC = () => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.user.userData);
	const cookies = new Cookies();
	const { mobile } = useSelector((state: RootState) => state.portal);

	const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const checkUserInfo = async (user: any) => {
		try {
			apiQuery(`registration/${user.id}`, {}, 'GET', {})
			.then((result: any) => {
				let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}`;				
				window.location.replace(redirect_url);
				return result || null;
			}).catch((error)=>{
				console.error('Error getting userr data', error);
				handleProfileDashboard();
				return null;
			})
		}
		catch (error) {
			console.error('Error getting user data', error);
			handleProfileDashboard();
			return null;
		}
	};

	const handleManage =async () => {
		const userData = await checkUserInfo(user);
		if(userData){
			let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}`;
			// let redirect_url = `http://localhost:3006`;
			window.location.replace(redirect_url);
		}
	};

	const handleProfile = () => {		
		let redirect_url = `${window.location.protocol}//${getHostName(true)}/registration`;
		//let redirect_url = `http://localhost:3000/registration`;		
		window.location.replace(redirect_url);
	};

	const handleProfileDashboard = () => {				
		let redirect_url = `${window.location.protocol}//${getHostName(true)}/registration?to=dashboard`;
		//let redirect_url = `http://localhost:3000/registration?to=dashboard`;		
		window.location.replace(redirect_url);
	};

	const handleLoginClick = () => {
		navigate('/login');
	};

	const handleLogout = async() => {
		dispatch(updateUser({ userData: null, registerData: null }));
		const redirectURI = await getMainUrl();
		const redirect_url = `${redirectURI}`;
		const domain = getDomain();	
		cookies.remove('access_token');
		cookies.remove('refresh_token');
		cookies.remove('access_token', domain);
		cookies.remove('refresh_token', domain);
		sessionStorage.clear();
		setAnchorEl(null);
	
		const currentUrl = window.location.href;
		const url = new URL(currentUrl);
		const hasPathname = url.pathname && url.pathname!== '/';
		if (hasPathname) {
			window.location?.replace(redirect_url);
		}
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLinkClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
		navigate(`/${url}`);
		setTimeout(() => {
			const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 200);
	};

	const formatPhoneNumber = (phone: string) => {
		var cleaned = ('' + phone).replace(/\D/g, '');
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			var intlCode = match[1] ? '+1 ' : '';
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return null;
	};

	React.useEffect(() => {
		const anchorLinks = document.querySelectorAll('a[href^="#"]');
		const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			const targetId = e.currentTarget.getAttribute('href')?.substring(1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		};

		anchorLinks.forEach((link) => {
			link.addEventListener('click', (e) => handleLinkClick(e as any));
		});
		// eslint-disable-next-line
	});

	return (
		<AppBar position='sticky' className='appbar'>
			<Toolbar className='toolbar'>
				<Grid container direction={'row'} alignItems={'center'}>
					<Grid item>
						<div onClick={handleLinkClick('#portal')}>
							<img src={Logo} alt='Logo' className='logo_img' />
						</div>
					</Grid>
					<Grid item xs>
						<MenuList style={{ display: 'flex', justifyContent: 'center' }}>
							<MenuItem onClick={handleMenuClose}>
								<div className='menu_item' onClick={handleLinkClick('#private-tutoring')}>
									PRIVATE TUTORING
								</div>
							</MenuItem>
							<MenuItem onClick={handleMenuClose}>
								<div className='menu_item' onClick={handleLinkClick('#custom-class')}>
									CUSTOM CLASSES
								</div>
							</MenuItem>
							<MenuItem onClick={handleMenuClose}>
								<div className='menu_item' onClick={handleLinkClick('#college-consulting')}>
									COLLEGE CONSULTING
								</div>
							</MenuItem>
							<MenuItem onClick={handleMenuClose}>
								<div className='menu_item' onClick={handleLinkClick('#technology')}>
									TECHNOLOGY
								</div>
							</MenuItem>
							<MenuItem onClick={handleMenuClose}>
								<div className='menu_item' onClick={handleLinkClick('#success-story')}>
									SUCCESS STORIES
								</div>
							</MenuItem>
						</MenuList>
					</Grid>
					<Grid item>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{user === null ? (
								<IconButton className='icon_btn background_color' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true' onClick={handleLoginClick}>
									<Person className='icon' />
								</IconButton>
							) : (
								<IconButton className='icon_btn background_color' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true' onClick={handleProfileClick}>
									<Avatar className='avator_icon'>{`${user?.firstName?.toUpperCase()?.slice(0, 1)}${user?.lastName?.toUpperCase()?.slice(0, 1)}`}</Avatar>
								</IconButton>
							)}
							{/* <IconButton className='icon_btn shopping_icon' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
								<ShoppingCartOutlined className='icon' />
							</IconButton> */}
							<a href={`tel:${mobile}`} className='phone_link'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<IconButton className='icon_btn background_color phone_icon' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
										<PhoneAndroidOutlined className='icon' />
									</IconButton>
									<Typography className='nav_typography background_color'> {formatPhoneNumber(mobile)} </Typography>
								</div>
							</a>
						</div>
					</Grid>
				</Grid>
				<StyledMenu
					id='menu'
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<MenuItem className='popup_menu_item' onClick={handleManage}>
						My Dashboard
					</MenuItem>
					<MenuItem className='popup_menu_item' onClick={handleProfile}>
						My Profile
					</MenuItem>
					<MenuItem className='popup_menu_item' onClick={handleLogout}>
						Logout
					</MenuItem>
				</StyledMenu>
			</Toolbar>
		</AppBar>
	);
};

export default NavigationBar;
