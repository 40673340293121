import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './EnrollClass.css';
import { Stack, Typography, Grid, TextField, InputAdornment, Card, IconButton } from '@mui/material';
import { CalendarMonth, LocationOn, MonetizationOn, Calculate } from '@mui/icons-material';
import { ReactComponent as CourseCodeIcon } from '../../assets/img/custom_class/coursecode.svg';
import { ReactComponent as ArrowIcon } from '../../assets/img/custom_class/arrow.svg';
import Navbar from '../Navbar';
import NavbarDashboard from '../NavbarDashboard';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { useDispatch } from 'react-redux';
import { updateCommon, updatePaymentState } from '../../reduxstate/slices/portalSlice';
import { common } from '../../assets/jss/messages';
import { getStrapiData, saveEnrollment, getHostName, getDomain } from '../../shared';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getApiUrl } from '../../apicall/apicall';
import { marked } from 'marked';
// import Carousel from 'react-material-ui-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Cookies } from 'react-cookie';

const settings = {
	showArrows: true,
	infiniteLoop: true,
	showIndicators: false,
	swipeable: true,
	useKeyboardArrows: true,
	showThumbs: false,
	showStatus: true,
};

const EnrollClassPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '100% !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const EnrollButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #053898 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	minWidth: '8vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #053898 100%)',
	},
	'&.Mui-disabled': {
		color: '#FFFF',
		background: 'linear-gradient(180deg, #E32636 0%, #9e1b32 100%)',
	},
}));

const EnrolledButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #70D291 0%, #50C878 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	minWidth: '8vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #70D291 0%, #50C878 100%)',
	},
	'&.Mui-disabled': {
		color: '#FFFF',
		background: 'linear-gradient(180deg, #70D291 0%, #50C878 100%)',
	},
}));

const EnrollClass: React.FC = () => {
	const params = new URLSearchParams(window.location.search);
	const id: string | null = params.get('code');
	const fromPath: string | null = params.get('from');
	const [searchcourses, setSearchcourses] = React.useState<any | null>('');
	const [courseList, setCourseList] = React.useState([]);
	const [firstLoad, setFirstLoad] = React.useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.user.userData);
	const register = useSelector((state: RootState) => state.user.registerData);
	const { mobile, emailId } = useSelector((state: RootState) => state.portal);

	useEffect(() => {
		if (firstLoad) {
			if (!isEmptyOrSpaces(id)) {
				setSearchcourses(id);
				populateSession(id, true);
			}
			setFirstLoad(false);
		}
		// eslint-disable-next-line
	}, []);

	const populateSession = async (courseId: string | null, onLoad?: boolean) => {
		if (!isEmptyOrSpaces(courseId)) {
			try {
				const today = new Date().toISOString().slice(0, 10);
				const filter = `filters[$and][0][code][$eqi]=${courseId}&filters[$and][1][isEnable][$eq]=true&filters[$and][2][endDate][$gte]=${today}`;
				const data = await getStrapiData(`courses?${filter}&populate=*&sort=displayOrder:asc`);
				if (data.length > 0) {
					// setCourseList(data);
					const enrollList: any = [];
					const chunkSize = 3;
					for (let i = 0; i < data.length; i += chunkSize) {
						enrollList.push(data.slice(i, i + chunkSize));
					}
					setCourseList(enrollList);
					dispatch(updateCommon({ courseId: courseId }));
					if (!onLoad) window.history.replaceState(null, '', `?from=${fromPath}&code=${courseId}`);
				} else {
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.class_code_not_found } }));
				}
			} catch (error) {
				dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.class_code_not_found } }));
			}
		} else {
			dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.class_code_required } }));
		}
	};

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchcourses(event.target.value);
	};

	const formatDate = (date: String | any) => {
		const dateParts = date.split('-');
		const utcDate = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[2], 10));
		const utc = utcDate.toDateString();
		return utc;
	};

	const formatDateMonth = (date: String | any) => {
		const dateParts = date.split('-');
		return `${dateParts[1]}/${dateParts[2]}`;
	};

	const formatTime = (timeString: any) => {
		let timeString12hr = new Date('1970-01-01T' + timeString + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
		return timeString12hr;
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			populateSession(searchcourses);
		}
	};

	const isEnrolled = (id: any) => {
		let isEnrolled = false;
		if (register && register.enrolledCourses) {
			if (register.enrolledCourses.includes(id)) {
				isEnrolled = true;
			}
		}
		return isEnrolled;
	};

	const handleEnrollClick = async (session?: any) => {
		try {
			if (session?.attributes?.totalCost <= 0) {
				let redirect_url = `${window.location.protocol}//${getHostName(true)}/enroll?from=${fromPath}&code=${searchcourses}`;
				dispatch(updateCommon({ redirectURL: redirect_url }));
				dispatch(updateCommon({ workflow: 'enroll' }));
				if (user === null) navigate('/confirm');
				else if (register === null) navigate('/registration');
				else {
					const result = await saveEnrollment(session.id);
					if (result) {
						window.location.reload();
					}
				}
			} else {
				// const response = await paymentSession(session);
				// const { data } = response;
				let redirect_url = `${window.location.protocol}//${getHostName(true)}/makePayment`;
				dispatch(updateCommon({ redirectURL: redirect_url }));
				dispatch(updateCommon({ workflow: 'enroll' }));
				dispatch(updatePaymentState({ isError: false, responseMessage: '' }));
				sessionStorage.setItem('redirect_url', '/enroll?from=' + fromPath + '&code=' + searchcourses);
				sessionStorage.setItem('enroll_course_id', session.id);
				const cookies = new Cookies();
				const domain = getDomain();
				cookies.remove('payment_source_from');
				cookies.set('payment_source_from', 'enroll', domain);
				if (user === null) navigate('/confirm');
				else if (register === null) navigate('/registration');
				else {
					navigate('/makePayment');
				}
			}
		} catch (error) {}
	};

	// const paymentSession = async (session: String | any) => {
	// 	// const path = 'https://api.mvpdev.alphaprep.com/transaction/session';
	// 	const path = getApiUrl(`transaction/session`);
	// 	const body = {
	// 		name: session.attributes.name,
	// 		amount: session.attributes.totalCost * 100,
	// 	};
	// 	const options = {
	// 		headers: {
	// 			'content-type': 'application/json',
	// 		},
	// 	};

	// 	const response = await axios.post(path, body, options);
	// 	return response;
	// };

	return (
		<EnrollClassPaper>
			{fromPath === 'portal' || fromPath === null ? <Navbar /> : ''}
			{fromPath === 'dashboard' ? <NavbarDashboard /> : ''}
			<div className='enroll_body'>
				<div className='enroll_header_parent'>
					<Stack direction={'row'} spacing={1} alignItems={'center'}>
						<Typography className='enroll_header'>Enter Your Code to See Your Choices</Typography>
						<TextField
							label=''
							autoFocus
							placeholder='Search Courses...'
							className='enroll_input'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<CourseCodeIcon className='enroll_icon' />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton className='enroll_icon_bg' onClick={() => populateSession(searchcourses)}>
											<ArrowIcon className='enroll_icon' />
										</IconButton>
									</InputAdornment>
								),
								classes: {
									root: 'enroll_input_text',
									notchedOutline: 'enroll_input_notch_line',
								},
							}}
							InputLabelProps={{
								sx: {
									'&::placeholder': {
										color: '#0146C7',
										opacity: 0.75,
									},
								},
							}}
							name='searchcourses'
							type='text'
							value={searchcourses}
							onChange={handleChange}
							onKeyDown={handleKeyPress}
						/>
						<div className='custom-classes-bottom'>
							<div>
								Need a course code? Contact Us at &nbsp;<a href={`mailto:${emailId}`}>{emailId}</a>&nbsp;|&nbsp;<a href={`tel:${mobile}`}>{mobile}</a>
							</div>
						</div>
					</Stack>
				</div>
				<br />
				{courseList?.length > 0 ? (
					<Carousel
						showArrows={settings.showArrows}
						infiniteLoop={settings.infiniteLoop}
						showIndicators={settings.showIndicators}
						swipeable={settings.swipeable}
						useKeyboardArrows={settings.useKeyboardArrows}
						showThumbs={settings.showThumbs}
						showStatus={settings.showStatus}
					>
						{(courseList || []).map((item: any, i: number) => (
							<Grid key={i} container spacing={2}>
								{(item || []).map((c: any, index: number) => (
									<Grid item xs={4} key={index} className='product-card' height={'inherit'}>
										<Card variant='elevation' className='course_cards'>
											<Stack spacing={2} className='course_cards_stack'>
												<Stack justifyContent={'center'} alignItems={'center'} spacing={2} className='course_cards_header'>
													<Typography className='course_cards_header_txt'>{c.attributes.name}</Typography>
												</Stack>
												<Stack justifyContent={'center'} alignItems={'center'} spacing={0} className='course_details_header'>
													<Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
														<Grid item xs={2}></Grid>
														<Grid item>
															<CalendarMonth className='course_details_icon'></CalendarMonth>
														</Grid>
														<Grid item xs>
															<Typography className='course_details_txt'>
																{formatDate(c.attributes.startDate)} | {formatDate(c.attributes.endDate)}
															</Typography>
														</Grid>
														<Grid item xs={2}></Grid>
													</Grid>
													<Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
														<Grid item xs={2}></Grid>
														<Grid item>
															<LocationOn className='course_details_icon'></LocationOn>
														</Grid>
														<Grid item xs>
															<Typography className='course_details_txt'>{c.attributes.location}</Typography>
														</Grid>
														<Grid item xs={2}></Grid>
													</Grid>
													<Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
														<Grid item xs={2}></Grid>
														<Grid item>
															<MonetizationOn className='course_details_icon'></MonetizationOn>
														</Grid>
														<Grid item xs>
															<Typography className='course_details_txt'>{c?.attributes?.totalCost <= 0 ? 'FREE' : c?.attributes?.totalCost}</Typography>
														</Grid>
														<Grid item xs={2}></Grid>
													</Grid>
													<Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
														<Grid item xs={2}></Grid>
														{c?.attributes?.desmosGiveaway ? (	<Grid item>
																<Calculate className='course_details_icon'></Calculate>
															</Grid>
														) : ('')}
														<Grid item xs>
															<Typography className='course_details_txt'>{c?.attributes?.desmosGiveaway ? 'Desmos subscription is free for ' + c?.attributes?.NumberOfDays +' days' : ''}</Typography>
														</Grid>
														<Grid item xs={2}></Grid>
													</Grid>
												</Stack>
												<Stack justifyContent={'center'} alignItems={'center'} spacing={1} className='course_schedule_stack'>
													<Typography className='course_schedule_header'>Course Schedule</Typography>
													<div className='course-schedule'>
														{c.attributes.schedule.map((s: any, index: number) => (
															<Grid key={index} container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={0}>
																<Grid item xs={3}></Grid>
																<Grid item xs={1}>
																	<Typography className='course_schedule_txt'>{formatDate(s.date).substring(0, 3)}</Typography>
																</Grid>
																<Grid item xs={1}>
																	<Typography className='course_schedule_txt'>{formatDateMonth(s.date)}</Typography>
																</Grid>
																<Grid item xs>
																	<Typography className='course_schedule_txt'>
																		{formatTime(s.startTime)} - {formatTime(s.EndTime)}
																	</Typography>
																</Grid>
																<Grid item xs={3}></Grid>
															</Grid>
														))}
													</div>
												</Stack>
												<Stack justifyContent={'center'} alignItems={'center'} className='course_schedule_summary_stack'>
													<Typography className='course_schedule_summary' dangerouslySetInnerHTML={{ __html: marked.parse(c?.attributes?.details) }}></Typography>
												</Stack>
												{/* <Stack justifyContent={'center'} alignItems={'center'} spacing={1} className='course_contact_stack'>
																<Typography className='course_schedule_summary'>Office Contact: {c.attributes.email}</Typography>
																<Typography className='course_schedule_summary'>Phone: {c.attributes.phoneNumber}</Typography>
															</Stack> */}
												<Stack justifyContent={'center'} alignItems={'center'}>
													{isEnrolled(c.id) ? (
														<EnrolledButton disabled variant='contained'>
															{'Enrolled'}
														</EnrolledButton>
													) : (
														<EnrollButton disabled={c.attributes.isFull} onClick={() => handleEnrollClick(c)} variant='contained'>
															{c.attributes.isFull ? 'Sold out' : 'Enroll now'}
														</EnrollButton>
													)}
												</Stack>
											</Stack>
										</Card>
									</Grid>
								))}
							</Grid>
						))}
					</Carousel>
				) : (
					<div style={{ height: '50vh' }}></div>
				)}
			</div>
		</EnrollClassPaper>
	);
};

export default EnrollClass;
