import axios, { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { getApiUrl } from '../apicall/apicall';
import { common } from '../assets/jss/messages';

let cmsUrl: string | null = null;

const init = async () => {
	cmsUrl = await getCmsURL();
};

const getDomain = (hostname?: any) => {
	let domain: string = hostname ? hostname : window.location.hostname;
	try {
		const urlParts = domain.split('.');
		domain = urlParts
			.slice(0)
			.slice(-(2))
			.join('.');
	} catch (error) {}
	return { path: '/', domain: domain };
};

const getHostName = (withPrefix?: boolean) => {
	const loc = window.location;
	const mode: string = loc.hostname === 'localhost' ? 'development' : 'production';
	const urlParts = loc.host.split('.');
	const domain = getDomain();
	const isWww = urlParts.length > 0 && urlParts[0]?.toLowerCase() === 'www' ? true : false;
	const prodURL = isWww ? (withPrefix ? `www.${domain?.domain}` : domain?.domain) : `${loc.host}`;
	console.log('prod URL:', prodURL);
	const host = mode === 'development' ? 'localhost' : prodURL;
	return host;
};

const getMainUrl = async () => {
	try {
		const currentUrl = window.location.href;
		const isLocal = currentUrl.includes('localhost');
		const hostName: any = getHostName(true);
		const hostList = hostName?.split('.');
		const hostListNew = hostList.filter((item: any) => item !== 'dashboard');
		const host = hostListNew.join('.');
		const prodURL = `${window.location.protocol}//${host}`;
		const mainUrl = isLocal ? 'http://localhost:3000' : prodURL;
		return mainUrl;
	} catch (error) {}
	return null;
};

const parseJwt = (token: any) => {
	try {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		return JSON.parse(jsonPayload);
	} catch (error) {}
	return null;
};

const callApi = async (url: any, method?: string, body?: any): Promise<AxiosResponse<any, any>> => {
	const cookies = new Cookies();
	const access_token = cookies.get('access_token');
	const options: any = {
		method: method || 'GET',
		url: getApiUrl(url),
		data: body || {},
		headers: {
			Authorization: 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		},
	};
	try {
		const response = await axios(options);
		return response;
	} catch (error: any) {
		if (error?.response?.status === 403 || error?.response?.status === 401) {
			const isSucess = await getRefreshToken();
			if (isSucess) {
				const response = await axios(options);
				return response;
			} else {
				redirectLogin();
				return Promise.reject('Authorization failed');
			}
		}
		return Promise.reject(error);
	}
};

const getRefreshToken = async () => {
	try {
		const cookies = new Cookies();
		const refresh_token = cookies.get('refresh_token');
		const access_token = cookies.get('access_token');
		const token = parseJwt(access_token);
		if (refresh_token && token.email) {
			const options: any = {
				method: 'POST',
				url: getApiUrl(`auth/refreshtoken`),
				data: {
					email: token.email,
					refreshtoken: refresh_token,
				},
			};
			const response = await axios(options);
			if (response?.status === 200 || response?.status === 201) {
				const result = await response.data;
				const domain = getDomain();
				cookies.remove('access_token');
				cookies.remove('refresh_token');
				cookies.set('access_token', result.access_token, domain);
				cookies.set('refresh_token', result.refresh_token, domain);
				return true;
			} else {
				console.error('Failed to fetch user data');
			}
		}
	} catch (error) {
		console.error('Error:', error);
	}
	return false;
};

const redirectLogin = () => {
	const cookies = new Cookies();
	const access_token = cookies.get('access_token');
	if (access_token) {
		const domain = getDomain();
		cookies.remove('access_token');
		cookies.remove('refresh_token');
		cookies.remove('access_token', domain);
		cookies.remove('refresh_token', domain);
		let redirect_url = `${window.location.protocol}//${window.location.host}/login`;
		window.location.replace(redirect_url);
	}
};

const getCurrentUser = async () => {
	try {
		const cookies = new Cookies();
		const access_token = cookies.get('access_token');
		if (access_token) {
			const token = parseJwt(access_token);
			const response = await callApi(`users/${token.id}`);
			if (response.status === 200) {
				const user = await response.data;
				return user || null;
			} else {
				console.error('Failed to fetch user data');
			}
		}
	} catch (error) {
		console.error('Error:', error);
	}
	return null;
};

const getUserData = async () => {
	try {
		const cookies = new Cookies();
		const access_token = cookies.get('access_token');
		if (access_token) {
			const token = parseJwt(access_token);
			const response = await callApi(`registration/${token.id}`);
			if (response.status === 200) {
				const regUser = await response.data;
				return regUser || null;
			} else {
				console.error('Failed to fetch registration data');
			}
		} else {
			console.error('Failed to fetch user data ');
		}
	} catch (error) {
		console.error('Error fetching user data', error);
	}

	return null;
};

const getCmsURL = async () => {
	if (cmsUrl) {
		return cmsUrl;
	}
	try {
		const response = await callApi(`auth/getcmsurl`);
		if (response.status === 200) {
			const data = await response.data;
			cmsUrl = data.contentUrl;
			return data.contentUrl;
		} else {
			console.error('Failed to get cms url');
		}
	} catch (error) {
		console.error('Failed to get cms url');
	}
	return null;
};

const getStrapiData = async (path: string | any): Promise<any> => {
	try {
		const contentAPI = await getCmsURL();
		if (contentAPI) {
			const apiUrl = `${contentAPI}/api/${path}`;
			const response = await axios.get(apiUrl);
			if (response.status === 200) {
				const data = await response.data;
				return data?.data || null;
			} else {
				console.error('Failed to get cms data');
			}
		} else {
			console.error('Failed to get cms url');
		}
	} catch (error) {
		console.error('Failed to get cms data');
	}
};

const desmosGiveaway = async (endDate: any, startDate: any, noOfDays: any) => {
	try {
		const regUser = await getUserData();
		if (regUser) {
			let expiryDate = new Date(startDate);
			expiryDate.setDate(expiryDate.getDate() + noOfDays);
			startDate = new Date(startDate).toISOString()?.slice(0, 10);
			const cookies = new Cookies();
			const access_token: string | null = cookies.get('access_token');
			const token = parseJwt(access_token);
			let plan = {
				planId: -1,
				period: noOfDays,
				startDate: startDate,
				endDate: new Date(expiryDate).toISOString()?.slice(0, 10),
				ongoing: true,
				isMonthly: false,
				name: 'Desmos - Giveaway',
			};
			const subList = regUser.subscribedDesmos ? regUser.subscribedDesmos : [];
			subList.push(plan);
			const body = { subscribedDesmos: subList };
			await callApi(`registration/${token.id}`, 'PUT', body);
		}
	} catch (error) {
		console.log(error);
	}
};

const saveEnrollment = async (courseId: string | null, type?: string, info?: any) => {
	try {
		const regUser = await getUserData();
		if (regUser) {
			const cookies = new Cookies();
			const access_token: string | null = cookies.get('access_token');
			const token = parseJwt(access_token);
			const courseList = regUser.enrolledCourses ? regUser.enrolledCourses : [];
			courseList.push(courseId);
			const desmosPlanList = regUser.subscribedDesmos ? regUser.subscribedDesmos : [];
			if (type === 'desmos' || type === 'desmos_extension' ) {
				desmosPlanList.push(info);
			}
			console.log('desmosPlanListafter',desmosPlanList)
			const body =
				(type === 'desmos' || type === 'desmos_extension')
					? { subscribedDesmos: desmosPlanList }
					: {
							enrolledCourses: courseList,
					  };
			const response = await callApi(`registration/${token.id}`, 'PUT', body);
			if (response.status === 200) {
				const data = await response.data;
				if (courseId) {
					await updateCourseDetails(courseId, token.id);
				}
				else{
						sendSubscribeMail(info, token.id);
				}
				return data || null;
			}
		}
	} catch (error) {
		console.log(error);
	}
	return null;
};

const sendSubscribeMail = (data: any, userId: string | null) => {
	try {
		const mailData = {
			id: userId,
			name: data.name,
			totalCost: data.amount,
			startDate: data.startDate,
			endDate: data.endDate,
			creditCardNum: data.creditCardNum,
			creditCardType: data.creditCardType,
			IsAutoRenewal: data.IsAutoRenewal,
		};
		const response = callApi('auth/subscribesuccess', 'POST', mailData);
		console.log('Mail sent successfully:', response);
	} catch (error) {
		console.log('Error sending mail:', error);
	}
};

const updateCourseDetails = async (courseId: string | null, userId: string | null) => {
	try {
		const filter = `filters[id][$eq]=${courseId}`;
		const data = await getStrapiData(`courses?${filter}&populate=*`);
		if (data.length > 0) {
			const availableSeat = Number(data[0]?.attributes?.availableSeat) || null;
			// const totalSeat = (Number(data[0]?.attributes?.totalSeat) || null)
			const aSeat = availableSeat && availableSeat > 0 ? availableSeat - 1 : 0;
			const isFull = aSeat === 0;
			const contentAPI = await getCmsURL();
			if (contentAPI) {
				const apiUrl = `${contentAPI}/api/courses/${courseId}`;
				const body = {
					data: {
						availableSeat: aSeat.toString(),
						isFull: isFull,
					},
				};
				const response = await axios.put(apiUrl, body);
				if (response.status === 200) {
					console.log('updated course details');
				} else {
					console.error('Failed to get cms data');
				}
			}
			const schedule: any = [];
			(data[0]?.attributes?.schedule || []).forEach((s: any) => {
				// schedule.push(`<TR ALIGN='CENTER'><TD>${formatDate(s.date).substring(0, 3)} ${formatDateMonth(s.date)}</TD><TD>${formatTime(s.startTime)} - ${formatTime(s.EndTime)}</TD></TR>`);
				schedule.push({ date: `${formatDate(s.date).substring(0, 3)} ${formatDateMonth(s.date)}`, time: `${formatTime(s.startTime)} - ${formatTime(s.EndTime)}` });
			});
			const mailData = {
				id: userId,
				name: data[0]?.attributes?.name,
				location: data[0]?.attributes?.location,
				totalCost: data[0]?.attributes?.totalCost <= 0 ? 'FREE' : data[0]?.attributes?.totalCost,
				startDate: data[0]?.attributes?.startDate,
				endDate: data[0]?.attributes?.endDate,
				schedule: schedule,
				desmosGiveaway : data[0]?.attributes?.desmosGiveaway,
				numberOfDays : data[0]?.attributes?.NumberOfDays
				// schedule: `<TABLE WIDTH='100%'>${schedule.join('')}</TABLE>`,
			};
			callApi(`auth/enrollsuccess`, 'POST', mailData);
		}
	} catch (error) {
		console.log(error);
	}
	return true;
};

const formatDate = (date: String | any) => {
	const dateParts = date.split('-');
	const utcDate = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[2], 10));
	const utc = utcDate.toDateString();
	return utc;
};

const formatDateMonth = (date: String | any) => {
	const dateParts = date.split('-');
	return `${dateParts[1]}/${dateParts[2]}`;
};

const formatTime = (timeString: any) => {
	let timeString12hr = new Date('1970-01-01T' + timeString + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
	return timeString12hr;
};

const validatePassword = (value: string) => {
	const isWhitespace = /^(?=.*\s)/;
	if (isWhitespace.test(value)) {
		return common.password_white_space;
	}
	const isContainsUppercase = /^(?=.*[A-Z])/;
	if (!isContainsUppercase.test(value)) {
		return common.password_upper_case;
	}

	const isContainsLowercase = /^(?=.*[a-z])/;
	if (!isContainsLowercase.test(value)) {
		return common.password_lower_case;
	}

	const isContainsNumber = /^(?=.*[0-9])/;
	if (!isContainsNumber.test(value)) {
		return common.password_digit;
	}
	// eslint-disable-next-line
	const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
	if (!isContainsSymbol.test(value)) {
		return common.password_special_symbol;
	}

	const isValidLength = /^.{8,20}$/;
	if (!isValidLength.test(value)) {
		return common.password_length;
	}
	return null;
};

init();

export { getDomain, getHostName, parseJwt, getCurrentUser, getUserData, getStrapiData, getCmsURL, validatePassword, saveEnrollment, callApi, getMainUrl, desmosGiveaway };
