export const common = {
	load_all: 'This action may take several minutes. Are you sure to continue?',
	load_error: 'Error while loading data',
	save_success: 'Saved successfully',
	save_error: 'Error while saving',
	required: 'This field is required',
	invalid_number: 'The value must be greater than 0',
	invalid_phone: 'Invalid phone number',
	invalid_email: 'Invalid email format',
	invalid_date: 'Invalid date format',
	invalid_time: 'Invalid time format',
	invalid_image: 'Invalid image format',
	invalid_password: 'Password standard does not meet',
	password_std_1: 'Password must contains: Minimum eight characters,',
	password_std_2: '1 uppercase, 1 number, 1 special character',
	password_std_3: 'At least one uppercase and one lowercase letter, At least one number and one special character',
	password_compare: 'Passwords not matching',
	required_field: '*Required',
	invalid_user: 'Invalid Email id or Password',
	invalid_google_acc: 'Invalid Google account',
	invalid_ms_acc: 'Invalid Microsoft account',
	user_not_exist: 'Invalid user',
	icon_close: 'Close',
	logout_error: 'Erorr during logout',
	delete_message: 'Are you sure to delete this?',
	delete_sub_message: 'You cannot undo this operation',
	success_registration: 'Registered Successfully. Please check your mail for next steps',
	success_registration_text_1: 'Registation Completed Successfully',
	success_registration_text_2: 'Please check your email for verification in order to login',
	error_registration: 'Something went wrong. User Registeration failed.',
	share_visual_part: 'Share Visual Part',
	copy_icon_tooltip: 'Copy to Clipboard',
	duplicate_user: 'The email you entered already exists. Please try login into the application',
	verify_success: 'Identity verification successful',
	verify_error: 'Error while verifying user',
	verify_vaild_user: 'This user was already validated',
	forgot_password_success: 'A mail has been sent to your registered email to help you reset your password',
	forgot_password_error: 'Invalid Email Id',
	forgot_password_text_1: 'Your password reset request has been received and an email with further instructions has been sent to your registered email address.',
	forgot_password_text_2: `Please check your inbox and spam folder if you don't see the email within the next few minutes. If you encounter any issues, please contact our support team for assistance.`,
	registration_info: 'Please enter your details to continue',
	reset_success: 'Password updated successfully, Login with your new password',
	reset_fail: 'Session expired',
	payment_succsss_1: 'Congratulations!',
	payment_succsss_2: 'You have successfully enrolled.',
	payment_succsss_3: 'Thank you for your payment',
	payment_succsss_4: 'Your transaction was successful.',
	payment_fail_1: 'Transaction failed!',
	payment_fail_2: 'Sorry, your payment could not be processed successfully.',
	payment_fail_3: 'Please check your payment details and try again. If the issue persists, contact support for assistance',
	class_code_not_found: 'The entered class code was not found',
	class_code_required: 'Please enter class code',
	duplicate_enroll: 'You have already enrolled this course',
	password_white_space: 'Password must not contain Whitespaces.',
	password_upper_case: 'Password must have at least one Uppercase Character.',
	password_lower_case: 'Password must have at least one Lowercase Character.',
	password_digit: 'Password must contain at least one Digit.',
	password_special_symbol: 'Password must contain at least one Special Symbol.',
	password_length: 'Password must be 8-20 Characters Long.',
	invalid_creditcard_number: 'Invalid credit card number',
};
