import React, { useEffect } from 'react';
import { Typography, Grid, Stack, SelectChangeEvent, MenuItem, TextField, Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CustomTextField from '../../components/CustomTextField';
import { Cookies } from 'react-cookie';
import CustomDropdownField from '../../components/CustomDropdownField';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './MakePayment.css';
import Logo from '../../assets/img/common/logo.png';
import creditCardLogo from '../../assets/img/common/credit_card.gif';
import { InputAdornment } from '@mui/material';
import { common } from '../../assets/jss/messages';
import { updateCommon, updatePaymentState } from '../../reduxstate/slices/portalSlice';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import apiQuery from '../../apicall/apiQuery';
import { getStrapiData } from '../../shared';
import { useSelector } from 'react-redux';
import { saveEnrollment, getCurrentUser, desmosGiveaway , getUserData} from '../../shared';
import { RootState } from '../../reduxstate';
import { updateUser } from '../../reduxstate/slices/userSlice';
import Processing from '../../components/Processing';


const PaymentPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	minWidth: '12vw',
	maxHeight: '3vw',
	width: 'auto',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const CustomSmallButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '0.8vmax',
	justifyContent: 'center',
	width: '7vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const MakePayment: React.FC = () => {
	const cookies = new Cookies();
	// const payment_source_from: string | null = sessionStorage.getItem('payment_source_from');
	const enroll_course_id: string | null = sessionStorage.getItem('enroll_course_id');
	const payment_source_from: string | null = cookies.get('payment_source_from');
	const desmos_sub_id: string | null = cookies.get('desmos_subscription_id');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { paymentState } = useSelector((state: RootState) => state.portal);
	// const [isNewUser, setIsNewUser] = React.useState(true);
	const [currentUser, setCurrentUser] = React.useState<any>(null);
	const [expMonths, setExpMonths] = React.useState([]);
	const [expYears, setExpYears] = React.useState([]);
	const [countryData, setCountryData] = React.useState([]);
	const [isProceesing, setIsProceesing] = React.useState(false);
	const [selectedCourseDetails, setSelectedCourseDetails] = React.useState<any>({});
	const [confirmDialogData, SetConfirmDialogData] = React.useState<any>({
		dialogOpen: false,
		dialogTitle: '',
		dialogMessage: '',
		dialogSubMessage: '',
		dialogBtnYes: 'Yes',
		dialogBtnNo: 'No',
		handleDlgYes: null,
		handleDlgNo: null,
	});
	const commonAutocompleteStyles = {
		'.MuiOutlinedInput-notchedOutline': {
			border: '2px solid #0146C6 !important',
			fontSize: '1.2vmax',
			borderRadius: '2vmax',
			color: 'var(--primary-font-color) !important',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#0146C6',
			borderRadius: '2vmax',
			opacity: '1',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#0146C6',
			borderRadius: '2vmax',
		},
		'.MuiInputLabel-outlined': {
			fontFamily: 'PoppinsSemiBold !important',
			fontSize: '1vmax !important',
			color: 'var(--primary-font-color) !important',
			opacity: 0.5,
		},
		'.MuiInputLabel-shrink': {
			marginLeft: '0.5vmax !important',
			opacity: '1 !important',
			fontSize: '0.85vmax !important',
		},
		'&.Mui-focused': {
			fontFamily: 'PoppinsSemiBold !important',
			fontSize: '0.85vmax !important',
			color: 'var(--primary-font-color) !important',
			marginLeft: '0.5vmax !important',
			opacity: '1',
		},
		'.MuiAutocomplete-input': {
			fontFamily: 'PoppinsSemiBold !important',
			fontSize: '1vmax !important',
			color: 'var(--primary-font-color) !important',
		},
	};

	const [paymentData, setpaymentData] = React.useState({
		data: {
			amount: '',
			// creditCardType: '',
			creditCardNum: '',
			cvv: '',
			expirationMonth: '',
			expirationYear: '',
			nameOnAccount: '',
			address: '',
			city: '',
			state: '',
			postCode: '',
			country: 'United States',
		},
		error: {
			// creditCardType: false,
			amount: false,
			creditCardNum: false,
			cvv: false,
			expirationMonth: false,
			expirationYear: false,
			nameOnAccount: false,
			address: false,
			city: false,
			state: false,
			postCode: false,
			country: false,
		},
		errorMessage: {
			required: common.required,
			creditCardNum: '',
			cvv: '',
			expirationMonth: '',
		},
	});
	useEffect(() => {
		initData();
		setExpMonthAndYear();
		fetchCountryData();
		// eslint-disable-next-line
	}, []);
	const setExpMonthAndYear = () => {
		const expMonthList: any = moment.months();
		const currentYear = new Date().getFullYear();
		const expYearList = Array.from({ length: 12 }, (_, index) => currentYear + index);
		let months: any = [];
		let years: any = [];
		expMonthList.forEach((month: string, idx: number) => {
			let id = String(idx + 1).padStart(2, '0');
			months.push({ id: id, name: month });
		});
		expYearList.forEach((year: number, idx: number) => {
			let id = year % 100;
			years.push({ id: id, name: year });
		});
		setExpMonths(months);
		setExpYears(years);
	};
	const fetchCountryData = async (): Promise<void> => {
		try {
			const response = await fetch(`https://restcountries.com/v3/all`);
			const data = await response.json();
			const options = data.map((country: any) => {
				let root_idd = country.idd.root ? country.idd.root : '';
				let suffix_idd = country.idd.suffixes && country.idd.suffixes.length > 0 ? country.idd.suffixes[0] : '';
				return {
					label: country.name.common,
					id: country.cca3,
					flag: country.flags[0],
					phCode: root_idd.concat('', suffix_idd),
				};
			});
			setCountryData(options);
		} catch (error) {
			console.warn('Fetch Error: ', error);
		}
	};
	const initData = async () => {
		let enrolmentAmount = '';
		const user = await getCurrentUser();
		if (payment_source_from === 'enroll') {
			const filter = `filters[id][$eq]=${enroll_course_id}`;
			const data = await getStrapiData(`courses?${filter}&populate=*`);
			enrolmentAmount = data && data.length > 0 && data[0].attributes ? data[0].attributes.totalCost : '';
			setpaymentData({
				...paymentData,
				data: {
					...paymentData.data,
					amount: enrolmentAmount.toString(),
				},
			});

			setSelectedCourseDetails(data && data.length > 0 ? data[0].attributes : {});
		} else if (payment_source_from === 'desmos_subscription') {
			const filter = `filters[id][$eq]=${desmos_sub_id}`;
			const data = await getStrapiData(`subscriptions?${filter}&populate=*`);
			enrolmentAmount = data && data.length > 0 && data[0].attributes ? data[0].attributes.Amount : '';
			setpaymentData({
				...paymentData,
				data: {
					...paymentData.data,
					amount: enrolmentAmount.toString(),
				},
			});
			let selectedPlan = data && data.length > 0 ? data[0].attributes : {};
			let plan = {
				planId: desmos_sub_id,
				period: selectedPlan.NumberOfDays,
				startDate: new Date(),
				endDate: calculateEndDate(selectedPlan.NumberOfDays, selectedPlan.IsAutoRenewal),
				ongoing: true,
				IsAutoRenewal: selectedPlan.IsAutoRenewal,
				name: `Desmos - ${selectedPlan.Period ?? ''}`,
				amount: selectedPlan.Amount,
				amountSummary: selectedPlan.AmountSummary,
			};
			setSelectedCourseDetails(plan);
		} else if (payment_source_from === 'desmos_subscription_extension') {
			const filter = `filters[id][$eq]=${desmos_sub_id}`;
			const MonthlyPlanData = await getStrapiData(`subscriptions?${filter}&populate=*`);
			let MonthlyPlan = MonthlyPlanData && MonthlyPlanData.length > 0 ? MonthlyPlanData[0].attributes : {};
			const regUser = await getUserData();
			const existingEndDateString: string = regUser.subscribedDesmos[0].endDate;
			const newStartDateString = addDaysToDate(existingEndDateString, 1, false); // Start date
			const newEndDateString = addDaysToDate(newStartDateString, MonthlyPlan.NumberOfDays, true); // End date
			enrolmentAmount = MonthlyPlanData && MonthlyPlanData.length > 0 && MonthlyPlanData[0].attributes ? MonthlyPlanData[0].attributes.Amount : '';
			setpaymentData({
				...paymentData,
				data: {
					...paymentData.data,
					amount: enrolmentAmount.toString(),
				},
			});

			let plan = {
				planId: desmos_sub_id,
				period:MonthlyPlan.NumberOfDays,
				startDate: newStartDateString,
				endDate : newEndDateString,
				ongoing: true,
				IsAutoRenewal: MonthlyPlan.IsAutoRenewal,
				name: `Desmos - ${MonthlyPlan.Period ?? ''}`,
				amount: MonthlyPlan.Amount,
				amountSummary: MonthlyPlan.AmountSummary,
			};

			console.log('plan',plan)
			setSelectedCourseDetails(plan);
		}
		setCurrentUser(user);
		// if (user) {
		// 	apiQuery(`registration/${user.id}`, {}, 'GET', {})
		// 		.then((result: any) => {
		// 			setIsNewUser(false);

		// 			// setpaymentDatails({ ...result, enrolmentAmount });
		// 		})
		// 		.catch((error: any) => {
		// 			setIsNewUser(true);
		// 		});
		// }
	};

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	const handleLogoClick = () => {
		navigate('/');
	};

	function addDaysToDate(dateString: any, days: any, isEndDate: boolean) {
		const date = new Date(dateString);
		date.setDate(date.getDate() + days);
	
		// Set the time based on whether it's an end date or start date
		if (isEndDate) {
			date.setHours(23, 59, 59, 0); // 23:59:59 for end date
		} else {
			date.setHours(0, 0, 0, 0); // 00:00:00 for start date
		}
	
		// Adjust for local timezone to ensure it reflects correctly in UTC
		const offset = date.getTimezoneOffset();
		date.setMinutes(date.getMinutes() - offset);
	
		return date.toISOString();
	}

	// const setpaymentDatails = (user: any) => {
	// 	setpaymentData({
	// 		...paymentData,
	// 		data: {
	// 			...paymentData.data,
	// 			amount: user.enrolmentAmount.toString(),
	// 			// creditCardType: user.paymentDetails && user.paymentDetails.creditCardType ? user.paymentDetails.creditCardType : '',
	// 			creditCardNum: user.paymentDetails && user.paymentDetails.creditCardNum ? user.paymentDetails.creditCardNum : '',
	// 			cvv: user.paymentDetails && user.paymentDetails.cvv ? user.paymentDetails.cvv : '',
	// 			expirationMonth: user.paymentDetails && user.paymentDetails.expirationMonth ? user.paymentDetails.expirationMonth : '',
	// 			expirationYear: user.paymentDetails && user.paymentDetails.expirationYear ? user.paymentDetails.expirationYear : '',
	// 			nameOnAccount: user.paymentDetails && user.paymentDetails.nameOnAccount ? user.paymentDetails.nameOnAccount : '',
	// 			country: user.paymentDetails && user.paymentDetails.country ? user.paymentDetails.country : '',
	// 		},
	// 	});
	// };

	const confirmPayment = () => {
		const isVaildAll = validateAll();
		if (isVaildAll) {
			let dialogMsg = `Are you sure you want to pay $${paymentData.data.amount} for the services?`;
			if (payment_source_from === 'enroll') {
				dialogMsg = `Are you sure you want to pay for this course?`;
			} else if (payment_source_from === 'desmos_subscription' || payment_source_from === 'desmos_subscription_extension' ) {
				dialogMsg = `Are you sure you want to pay for this plan?`;
			}
			SetConfirmDialogData({
				dialogOpen: true,
				dialogTitle: 'Confirm Payment',
				dialogMessage: dialogMsg,
				dialogSubMessage: '',
				dialogBtnYes: 'Yes',
				dialogBtnNo: 'No',
				handleDlgYes: () => {
					return new Promise(async (resolve: any, reject: any) => {
						resolve();
						setIsProceesing(true);
						// if (isSaveAndSubmit) {
						// 	paymentSave();
						// } else {
						paymentSubmit();
						// }
					});
				},
				handleDlgNo: () => {
					return new Promise((resolve: any, reject: any) => {
						resolve();
					});
				},
			});
		}
	};

	// const paymentSave = () => {
	// 	const updatedDetails = {
	// 		paymentDetails: {
	// 			amount: paymentData.data.amount,
	// 			creditCardNum: paymentData.data.creditCardNum,
	// 			expirationMonth: paymentData.data.expirationMonth,
	// 			expirationYear: paymentData.data.expirationYear,
	// 			nameOnAccount: paymentData.data.nameOnAccount,
	// 			country: paymentData.data.country,
	// 		},
	// 	};
	// 	if (isNewUser) {
	// 		apiQuery('registration', {}, 'POST', updatedDetails)
	// 			.then((result: any) => {
	// 				paymentSubmit();
	// 			})
	// 			.catch((error: any) => {
	// 				setIsProceesing(false);
	// 				dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.save_error } }));
	// 			});
	// 	} else {
	// 		apiQuery(`registration/${currentUser.id}`, {}, 'PUT', updatedDetails)
	// 			.then((result: any) => {
	// 				paymentSubmit();
	// 			})
	// 			.catch((error: any) => {
	// 				setIsProceesing(false);
	// 				dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.save_error } }));
	// 			});
	// 	}
	// };

	const paymentSubmit = () => {
		let body = {
			amount: paymentData.data.amount,
			creditCardNum: paymentData.data.creditCardNum,
			cvv: paymentData.data.cvv,
			expirationDate: paymentData.data.expirationMonth && paymentData.data.expirationYear ? paymentData.data.expirationMonth.concat('', paymentData.data.expirationYear) : '',
			nameOnAccount: paymentData.data.nameOnAccount,
			address: paymentData.data.address,
			city: paymentData.data.city,
			state: paymentData.data.state,
			postCode: paymentData.data.postCode,
			country: paymentData.data.country,
		};
		apiQuery('creditBankAccount', {}, 'POST', body)
			.then((result: any) => {
				let message = result.messages && result.messages.message && result.messages.message.length > 0 ? result.messages.message[0] : {};
				if (result.responseCode === '1') {
					let paymentDetails: any = {
						transactionId: result.transId,
						userId: currentUser ? currentUser.id : '',
						courseId:
							payment_source_from === 'enroll' && !isEmptyOrSpaces(enroll_course_id)
								? enroll_course_id
								: payment_source_from === 'desmos_subscription' && !isEmptyOrSpaces(desmos_sub_id)
								? desmos_sub_id
								: '',
						creditCardType: result.accountType,
						creditCardNum: body.creditCardNum,
						amount: body.amount,
						nameOnAccount: body.nameOnAccount,
						address: body.address,
						city: body.city,
						state: body.state,
						postCode: body.postCode,
						country: body.country,
					};
					apiQuery('payment', {}, 'POST', paymentDetails)
						.then((result: any) => {})
						.catch((error: any) => {
							dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.save_error } }));
						});
					dispatch(updatePaymentState({ ...paymentState, isError: false, responseMessage: message.description ? message.description : '', data: paymentData.data }));
					if (payment_source_from === 'enroll' && !isEmptyOrSpaces(enroll_course_id)) {
						sessionStorage.removeItem('enroll_course_id');
						if (selectedCourseDetails?.desmosGiveaway) {
							desmosGiveaway(selectedCourseDetails?.endDate, selectedCourseDetails?.startDate, selectedCourseDetails?.NumberOfDays);
						}
						saveEnrollment(enroll_course_id).then((result) => {
							if (result) dispatch(updateUser({ registerData: result }));
							navigate('/payment');
							setIsProceesing(false);
						});
					} else if ((payment_source_from === 'desmos_subscription' || payment_source_from === 'desmos_subscription_extension') && !isEmptyOrSpaces(desmos_sub_id)) {
						cookies.remove('desmos_subscription_id');
						let type;
						if (payment_source_from === 'desmos_subscription') {
							type = 'desmos';
						} else if (payment_source_from === 'desmos_subscription_extension') {
							type = 'desmos_extension';
						} 
						let desmosPaymentDatails: any = {};
						if (selectedCourseDetails.IsAutoRenewal) {
							desmosPaymentDatails = { transactionId: paymentDetails.transactionId, cvv: body.cvv, expirationDate: body.expirationDate };
						}
                        desmosPaymentDatails.creditCardNum = paymentDetails?.creditCardNum?.slice(-4);
						desmosPaymentDatails.creditCardType = paymentDetails?.creditCardType;
						saveEnrollment(null, type, { ...selectedCourseDetails, ...desmosPaymentDatails }).then((result) => {
							if (result) dispatch(updateUser({ registerData: result }));
							navigate('/payment');
							setIsProceesing(false);
						});
					} else {
						setIsProceesing(false);
						navigate('/payment');
					}
				} else {
					dispatch(updatePaymentState({ ...paymentState, isError: true, responseMessage: message.description ? message.description : '', data: paymentData.data }));
					navigate('/payment');
					setIsProceesing(false);
				}
			})
			.catch((error: any) => {
				setIsProceesing(false);
				let reason = error.reason && error.reason.error && error.reason.error.length > 0 ? error.reason.error[0] : {};
				if (reason.errorCode === '6' || reason.errorCode === '8') {
					setpaymentData({
						...paymentData,
						error: {
							...paymentData.error,
							creditCardNum: reason.errorCode === '6',
							expirationMonth: reason.errorCode === '8',
						},
						errorMessage: {
							...paymentData.errorMessage,
							creditCardNum: reason.errorCode === '6' ? reason.errorText : '',
							expirationMonth: reason.errorCode === '8' ? reason.errorText : '',
						},
					});
				} else {
					let errMsg = !reason.errorText && error.reason && error.reason.message && error.reason.message.length > 0 ? error.reason.message[0] : {};
					dispatch(updatePaymentState({ ...paymentState, isError: true, responseMessage: reason.errorText ? reason.errorText : errMsg.text ? errMsg.text : '' }));
					navigate('/payment');
				}
			});
	};

	function calculateEndDate(days: number, IsAutoRenewal: boolean) {
		let startDate = new Date();
		let endDate = new Date(startDate);
	
		// Calculate the end date
		endDate.setDate(startDate.getDate() + days);
	
		// Set the time to 23:59:59 on the end date
		endDate.setHours(23, 59, 59, 0);
	
		// Adjust for local timezone offset
		const offset = endDate.getTimezoneOffset();
		endDate.setMinutes(endDate.getMinutes() - offset);
	
		return endDate;
	}
	
	
	const handleDrpDownChange = (event: SelectChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		setpaymentData((prevUserData) => ({
			...prevUserData,
			data: {
				...prevUserData.data,
				[name]: value,
			},
			error: {
				...prevUserData.error,
				[name]: false,
			},
			errorMessage: {
				...prevUserData.errorMessage,
				[name]: '',
			},
		}));
	};
	const formatDate = (date: String | any) => {
		const dateParts = date.split('-');
		const utcDate = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[2], 10));
		const utc = utcDate.toDateString();
		return utc;
	};

		const formatDateMonth = (date: String | any) => {
			const dateParts = date.split('-');
			return `${dateParts[1]}/${dateParts[2]}`;
		};

	const formatTime = (timeString: any) => {
		let timeString12hr = new Date('1970-01-01T' + timeString + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
		return timeString12hr;
	};

	const formatDateMMDDYYYY = (date: string | Date | undefined) => {
		if (!date) return '';
		const dateObj = typeof date === 'string' ? new Date(date) : date;
		const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are zero-based
		const day = ('0' + dateObj.getDate()).slice(-2); // Days are zero-based
		const year = dateObj.getFullYear();
		return `${month}-${day}-${year}`;
	};
	
	const validateAll = () => {
		let isValidData = true;

		let amount = false;
		let creditCardNum = false;
		let cvv = false;
		let expirationMonth = false;
		let expirationYear = false;
		let nameOnAccount = false;
		let country = false;
		let address = false;
		let city = false;
		let state = false;
		let postCode = false;
		if (isEmptyOrSpaces(paymentData.data.nameOnAccount)) {
			nameOnAccount = true;
		}
		if (isEmptyOrSpaces(paymentData.data.amount)) {
			amount = true;
		}
		if (isEmptyOrSpaces(paymentData.data.creditCardNum) || !/^\d{10,20}$/.test(paymentData.data.creditCardNum)) {
			creditCardNum = true;
		}
		if (isEmptyOrSpaces(paymentData.data.cvv) || !/^\d{3,4}$/.test(paymentData.data.cvv)) {
			cvv = true;
		}

		if (isEmptyOrSpaces(paymentData.data.expirationMonth)) {
			expirationMonth = true;
		}
		if (isEmptyOrSpaces(paymentData.data.expirationYear)) {
			expirationYear = true;
		}
		// const creditCardTest = validator.isCreditCard(paymentData.data.creditCardNum);
		// if (!isEmptyOrSpaces(paymentData.data.creditCardNum) && !creditCardTest) {
		// 	creditCardNum = true;
		// }

		if (creditCardNum || cvv || expirationMonth || expirationYear || nameOnAccount || amount) {
			isValidData = false;
		}

		setpaymentData({
			...paymentData,
			error: {
				// creditCardType: false,
				amount: amount,
				creditCardNum: creditCardNum,
				cvv: cvv,
				expirationMonth: expirationMonth,
				expirationYear: expirationYear,
				nameOnAccount: nameOnAccount,
				country: country,
				address: address,
				city: city,
				state: state,
				postCode: postCode,
			},
			errorMessage: {
				...paymentData.errorMessage,
				required: common.required,
				creditCardNum: isEmptyOrSpaces(paymentData.data.creditCardNum) ? common.required : 'Invalid credit card number',
				cvv: isEmptyOrSpaces(paymentData.data.cvv) ? common.required : 'Invalid CVV code.',
				expirationMonth: isEmptyOrSpaces(paymentData.data.expirationMonth) ? common.required : '',
				// required: creditCardNum
				// 	? isEmptyOrSpaces(paymentData.data.creditCardNum)
				// 		? common.required
				// 		: 'Invalid credit card number'
				// 	: cvv
				// 	? isEmptyOrSpaces(paymentData.data.cvv)
				// 		? common.required
				// 		: 'Invalid CVV code.'
				// 	: common.required,
			},
		});

		return isValidData;
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		let isError = false;
		if (event.target.required) {
			if (isEmptyOrSpaces(value)) {
				isError = true;
			}
		}

		setpaymentData({
			...paymentData,
			data: {
				...paymentData.data,
				[name]: value,
			},
			error: {
				...paymentData.error,
				[name]: isError,
			},
			errorMessage: {
				...paymentData.errorMessage,
				[name]: isError ? (isEmptyOrSpaces(value) ? common.required : common.invalid_email) : common.required,
			},
		});
	};

	const handleCountryChange = (event: React.ChangeEvent<{}>, value: any, country: string) => {
		const name = country;
		const studentCountry = value?.label || '';
		setpaymentData((prevPaymentData) => ({
			...prevPaymentData,
			data: {
				...prevPaymentData.data,
				[name]: studentCountry,
			},
			error: {
				...prevPaymentData.error,
				[name]: false,
			},
			errorMessage: {
				...prevPaymentData.errorMessage,
				[name]: '',
			},
		}));
	};
	const resetData = () => {
		SetConfirmDialogData({
			dialogOpen: false,
			dialogTitle: '',
			dialogMessage: '',
			dialogSubMessage: '',
			dialogBtnYes: 'Yes',
			dialogBtnNo: 'No',
			handleDlgYes: null,
			handleDlgNo: null,
		});
	};

	const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>, type?: string) => {
		const name = event.target.name;
		let only_number = event.target.value.replace(/[^0-9]/g, '');
		let final_number: any;

		let isError = false;
		let isPhoneNum = false;

		if (name === 'cvv') {
			only_number = only_number.length > 4 ? only_number.substring(0, 4) : only_number;
			final_number = only_number;
		} else if (name === 'creditCardNum') {
			only_number = only_number.length > 20 ? only_number.substring(0, 20) : only_number;
			final_number = only_number;
		} else if (name === 'amount') {
			final_number = event.target.value.replace(/[^0-9.]|(?<=\..*)\./g, '');
		} else {
			final_number = only_number;
		}
		if (event.target.required && isEmptyOrSpaces(final_number)) {
			isError = true;
		}

		setpaymentData({
			...paymentData,
			data: {
				...paymentData.data,
				[name]: final_number,
			},
			error: {
				...paymentData.error,
				[name]: isError,
			},
			errorMessage: {
				...paymentData.errorMessage,
				[name]: isError ? (isPhoneNum ? common.invalid_phone : common.required) : common.required,
			},
		});
	};
	const { dialogOpen, dialogTitle, dialogMessage, handleDlgYes, handleDlgNo } = confirmDialogData;
	return (
		<>
			<PaymentPaper>
				<Processing isProceesing={isProceesing} />
				<div className='make_payment_header'>
					<div className='make_payment_banner'></div>
					<img className='make_payment_logo' alt='' src={Logo} onClick={handleLogoClick} />
				</div>
				<div className='payment_info'>
					Problems? Contact us at&nbsp;<a href='mailto:info@alphaprep.com'>info@alphaprep.com</a>&nbsp;or 1-888-84-ALPHA
				</div>
				<div className='credit_card_logo'>
					<img alt='' src={creditCardLogo} />
				</div>

				<div className='make_payment_content'>
					<div className='make_payment_left'>
						<Typography className='sign_in_text'>Billing Address</Typography>
						<br />
						<Grid container className='make_payment_text' direction={'column'} spacing={2}>
							<Grid item xs>
								<CustomTextField
									label='Name On Account'
									required
									name='nameOnAccount'
									type='text'
									error={paymentData.error.nameOnAccount}
									helperText={paymentData.errorMessage.required}
									value={paymentData.data.nameOnAccount}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs>
								<CustomTextField label='Address' multiline={true} rows={2} name='address' type='text' value={paymentData.data.address} onChange={handleChange} />
							</Grid>
							<Grid item xs spacing={1} container>
								<Grid item xs>
									<CustomTextField label='City' name='city' type='text' value={paymentData.data.city} onChange={handleChange} />
								</Grid>
								<Grid item xs>
									<CustomTextField label='State' name='state' type='text' value={paymentData.data.state} onChange={handleChange} />
								</Grid>
							</Grid>
							<Grid item xs spacing={1} container>
								<Grid item xs>
									<CustomTextField
										label='Postal Code'
										name='postCode'
										type='text'
										value={paymentData.data.postCode}
										onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
											event.preventDefault();
											handleChangeNumber(event, 'none');
										}}
									/>
								</Grid>
								<Grid item xs>
									<Autocomplete
										id='paymentCountry'
										sx={commonAutocompleteStyles}
										value={paymentData.data.country}
										options={countryData}
										onChange={(event, value) => {
											handleCountryChange(event, value, 'country');
										}}
										autoHighlight
										renderInput={(params) => (
											<TextField
												{...params}
												label='Country'
												sx={{
													'& .MuiFormLabel-asterisk': {
														color: 'red',
													},
												}}
											/>
										)}
										renderOption={(props, option: any) => (
											<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
												<img loading='lazy' width='20' srcSet={option.flag} src={option.flag} alt='flag' />
												{option.label}
											</Box>
										)}
									/>
								</Grid>
								{/* <CustomTextField label='COUNTRY' style={{ width: '70%' }} name='country' type='text' value={paymentData.data.country} onChange={handleChange} /> */}
							</Grid>
						</Grid>
					</div>
					<div className='make_payment_right'>
						<Typography className='sign_in_text'>Payment Details</Typography>
						<br />
						<Grid container className='make_payment_text' direction={'column'} spacing={2}>
							<Grid item xs>
								<CustomTextField
									label='Amount To Pay'
									name='amount'
									type='text'
									required
									disabled={payment_source_from === 'enroll' || payment_source_from === 'desmos_subscription' ? true : false}
									error={paymentData.error.amount}
									helperText={paymentData.errorMessage.required}
									value={paymentData.data.amount}
									InputProps={{
										startAdornment: <InputAdornment position='start'>$</InputAdornment>,
									}}
									onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
										event.preventDefault();
										handleChangeNumber(event, 'none');
									}}
								/>
							</Grid>
							{/* <Grid item xs>
							
							<CustomDropdownField
								label='Credit Card Type'
								name='creditCardType'
								// error={userData.error.creditCardType}
								// helperText={userData.errorMessage.required}
								value={paymentData.data.creditCardType}
								style={{ width: '70%' }}
								onChange={handleDrpDownChange}
							>
								{(creditCardType || []).map((type: any, idx) => (
									<MenuItem key={type.id} value={type.id}>
										{type.name}
									</MenuItem>
								))}
							</CustomDropdownField>
						</Grid> */}
							<Grid item xs>
								<CustomTextField
									label='Credit Card No'
									name='creditCardNum'
									type='text'
									required
									error={paymentData.error.creditCardNum}
									helperText={paymentData.errorMessage.creditCardNum}
									value={paymentData.data.creditCardNum}
									onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
										event.preventDefault();
										handleChangeNumber(event, 'none');
									}}
								/>
							</Grid>
							<Grid item xs>
								<CustomTextField
									label='CVV'
									name='cvv'
									type='password'
									required
									error={paymentData.error.cvv}
									helperText={paymentData.errorMessage.cvv}
									value={paymentData.data.cvv}
									onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
										event.preventDefault();
										handleChangeNumber(event, 'none');
									}}
								/>
							</Grid>
							<Grid item xs spacing={1} container>
								<Grid item xs>
									<CustomDropdownField
										label='Expiration Month'
										name='expirationMonth'
										required
										error={paymentData.error.expirationMonth}
										helperText={paymentData.errorMessage.expirationMonth}
										value={paymentData.data.expirationMonth}
										onChange={handleDrpDownChange}
									>
										{(expMonths || []).map((mnth: any, idx) => (
											<MenuItem key={idx} value={mnth.id}>
												{`${mnth.name} (${idx + 1})`}
											</MenuItem>
										))}
									</CustomDropdownField>
								</Grid>

								<Grid item xs>
									<CustomDropdownField
										label='Expiration Year'
										name='expirationYear'
										required
										error={paymentData.error.expirationYear}
										helperText={paymentData.errorMessage.required}
										value={paymentData.data.expirationYear}
										onChange={handleDrpDownChange}
									>
										{(expYears || []).map((year: any, idx) => (
											<MenuItem key={idx} value={year.id}>
												{year.name}
											</MenuItem>
										))}
									</CustomDropdownField>
								</Grid>
							</Grid>
						</Grid>
						<div className='make_payment_buttons'>
							<a target='_blank' href='/cancellationPolicy' className='make_payment_cancel'>
								Cancellation Policy
							</a>
							<CustomButton
								variant='contained'
								onClick={() => {
									confirmPayment();
								}}
							>
								SUBMIT
							</CustomButton>
							{/* {currentUser && (
								<CustomButton
									variant='contained'
									onClick={() => {
										confirmPayment(true);
									}}
								>
									SAVE & SUBMIT
								</CustomButton>
							)} */}
						</div>
					</div>
				</div>
				<div>
					<p className='makepayment_footer_info'>
						The security of your sensitive information is important to us. When you enter sensitive information such as a credit card number and/or social security number on our registration or order
						forms, we encrypt that information.
					</p>
					<Stack direction={'row'} justifyContent={'space-between'} className='makepayment_footer_text' alignItems={'center'} spacing={4}>
						<Typography>Your Information is safe and secure</Typography>
						<Typography>You can review this payment before it’s final.</Typography>
					</Stack>
				</div>
			</PaymentPaper>
			<Dialog open={dialogOpen} fullWidth maxWidth='sm' onClose={handleDlgNo} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title' className='confirm_dialog_title'>
					{dialogTitle}
				</DialogTitle>

				{(payment_source_from === 'enroll' || payment_source_from === 'desmos_subscription' || payment_source_from === 'desmos_subscription_extension') && (
					<table border={1} style={{ marginLeft: 30, marginRight: 30 }}>
						<tbody>
							<tr>
								<td>Name</td>
								<td>{selectedCourseDetails.name}</td>
							</tr>
							<tr>
								<td>Cost</td>
								<td>{paymentData && paymentData.data ? `$${paymentData.data.amount}` : ''}</td>
							</tr>
							<tr>
								<td>Start Date</td>
								    <td>
									{formatDateMMDDYYYY(selectedCourseDetails?.startDate)}
    </td>
							</tr>
							<tr>
								<td>{payment_source_from === 'desmos_subscription' && selectedCourseDetails?.IsAutoRenewal ? 'Renewal date' : 'End Date'}</td>
								<td>
								{formatDateMMDDYYYY(selectedCourseDetails?.endDate)}
    </td>
							</tr>
							{payment_source_from === 'enroll' && selectedCourseDetails.schedule && selectedCourseDetails.schedule.length > 0 && (
								<tr>
									<td>Schedule</td>
									<td>
										<table>
											{selectedCourseDetails.schedule.map((scheduled: any) => {
												return (
													<tr>
														<td width={20}>{formatDate(scheduled.date).substring(0, 3)}</td>
														<td width={100}>{formatDateMonth(scheduled.date)}</td>
														 <td>{`${formatTime(scheduled.startTime)} - ${formatTime(scheduled.EndTime)}`}</td> 
													</tr>
												);
											})}
										</table>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				)}
				<DialogContent>
					<DialogContentText id='alert-dialog-description' className='confirm_dialog_msg'>
						{dialogMessage}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={4}>
						<CustomSmallButton
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								if (handleDlgNo) {
									handleDlgNo()
										.then((data: any) => {
											resetData();
										})
										.catch(() => {
											resetData();
										});
								} else {
									resetData();
								}
							}}
							size='small'
						>
							No
						</CustomSmallButton>
						<CustomSmallButton
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								if (handleDlgYes) {
									handleDlgYes()
										.then((data: any) => {
											resetData();
										})
										.catch(() => {
											resetData();
										});
								} else {
									resetData();
								}
							}}
							size='small'
							autoFocus
						>
							Yes
						</CustomSmallButton>
					</Stack>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default MakePayment;
