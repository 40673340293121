import './PageNotFound.css';
import errorImg from '../../assets/img/common/404.svg';
import astronautImg from '../../assets/img/common/astronaut.svg';
import earthImg from '../../assets/img/common/earth.svg';
import moonImg from '../../assets/img/common/moon.svg';
import rocketImg from '../../assets/img/common/rocket.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function PageNotFound() {
	const navigate = useNavigate();
	const handleHomeClick = () => {
		navigate('/');
	};

	useEffect(() => {					
		navigate('/');
	});

	return (
		<div id='404-page' className='notfound-body'>
			<div className='stars'>
				<div className='central-body'>
					<img className='image-404' src={errorImg} alt='' width='25%' />
					<div className='btn-go-home' onClick={handleHomeClick}>
						GO BACK HOME
					</div>
				</div>
				<div className='objects'>
					<img className='object_rocket' src={rocketImg} alt='' width='5%' />
					<div className='earth-moon'>
						<img className='object_earth' src={earthImg} alt='' width='10%' />
						<img className='object_moon' src={moonImg} alt='' width='7%' />
					</div>
					<div className='box_astronaut'>
						<img className='object_astronaut' src={astronautImg} alt='' width='15%' />
					</div>
				</div>
				<div className='glowing_stars'>
					<div className='star'></div>
					<div className='star'></div>
					<div className='star'></div>
					<div className='star'></div>
					<div className='star'></div>
				</div>
			</div>
		</div>
	);
}
