import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '../assets/jss/theme';
import './Font.css';
import './App.css';
import { CookiesProvider } from 'react-cookie';
import Portal from '../pages/Portal';
import Login from '../pages/Login';
import SignUp from '../pages/Signup';
import ForgetPassword from '../pages/ForgetPassword';
import ResetPassword from '../pages/ResetPassword';
import UserVerification from '../pages/UserVerification';
import Registration from '../pages/Registration';
import EnrollClass from '../pages/EnrollClass';
import Payment from '../pages/Payment';
import MakePayment from '../pages/MakePayment';
import CancellationPolicy from '../pages/CancellationPolicy';
import DiagnosticTest from '../pages/DiagnosticTest';
import ErrorPage from '../pages/ErrorPage';
import PageNotFound from '../pages/PageNotFound';
import CustomSnackbar from '../components/CustomSnackbar';
import CustomDialog from '../components/CustomDialog';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import EmploymentOpportunities from '../pages/EmploymentOpportunities';
import { useDispatch } from 'react-redux';
import { updateUser } from '../reduxstate/slices/userSlice';
import { updateCommon, updateSysPref } from '../reduxstate/slices/portalSlice';
import { getStrapiData, getCurrentUser, getUserData } from '../shared';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import TermsConditions from '../pages/TermsConditions';

function App() {
	const themes = React.useState('light');
	const dispatch = useDispatch();

	React.useEffect(() => {
		try {
			initData();
		} catch (error) {}
		// eslint-disable-next-line
	}, []);

	const initData = async () => {
		const user = await getCurrentUser();
		if (user) {
			dispatch(updateUser({ userData: user }));
		}
		const regUser = await getUserData();
		if (regUser) {
			dispatch(updateUser({ registerData: regUser }));
		}
		GetGeneralData();
		GetSystemPreferenceData();
	};

	const GetGeneralData = async () => {
		try {
			const data = await getStrapiData('generals');
			if (data.length > 0) {
				dispatch(updateCommon({ mobile: data.data[0].attributes.phoneNumber, emailId: data.data[0].attributes.email, whatsapp: data.data[0].attributes.whatsApp }));
			}
		} catch (error) {}
	};

	const GetSystemPreferenceData = async () => {
		try {
			const data = await getStrapiData('system-preferences');
			if (data.length > 0) {
				data.forEach((item: any) => {
					try {
						dispatch(updateSysPref({ [item.attributes.key]: item.attributes.value }));
					} catch (error) {
						console.log(error);
					}
				});
			}
		} catch (error) {}
	};

	const APP_ROUTE = [
		{
			errorElement: <ErrorPage />,
			children: [
				{ index: true, element: <Portal /> },
				{ path: '/', element: <Portal /> },
				{ path: '/login', element: <Login /> },
				{ path: '/joinus', element: <SignUp /> },
				{ path: '/forgotPassword', element: <ForgetPassword /> },
				{ path: '/resetPassword', element: <ResetPassword /> },
				{ path: '/registration', element: <Registration /> },
				{ path: '/enroll/*', element: <EnrollClass /> },
				{ path: '/verifyuser', element: <UserVerification /> },
				{ path: '/confirm', element: <CustomDialog /> },
				{ path: '/payment/*', element: <Payment /> },
				{ path: '/makePayment/*', element: <MakePayment /> },
				{ path: '/cancellationPolicy/*', element: <CancellationPolicy /> },
				{ path: '/diagnostic-test', element: <DiagnosticTest /> },
				{ path: '/diagnostic-test', element: <DiagnosticTest /> },
				{ path: '/privacy-policy', element: <PrivacyPolicy /> },
				{ path: '/employment-opportunities', element: <EmploymentOpportunities /> },
				{ path: '/terms-and-conditions', element: <TermsConditions /> },
				{ path: '*', element: <PageNotFound /> },
			],
		},
	];

	const router = createBrowserRouter([
		{
			children: [
				{
					path: '/',
					children: [...APP_ROUTE],
				},
			],
		},
	]);

	return (
		<CookiesProvider defaultSetOptions={{ path: '/' }}>
			<ThemeProvider theme={createTheme(themes)}>
				<CssBaseline />
				<RouterProvider router={router} />
				<CustomSnackbar />
			</ThemeProvider>
		</CookiesProvider>
	);
}

export default App;
